import { createContext } from "react";
import { Event } from './types';

export interface Modals {
    showEvent: (event: Event | null) => void;
    showControls: () => void;
}

export const ModalsContext = createContext({
    showEvent: (_event: Event | null) => { },
    showControls: () => { },
});

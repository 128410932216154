import { find } from "remeda";
import { CountryCode, ZoneCode, countries, getZoneCode } from "./countries";
import { Regions } from "./types";

export type RegionCode = 'all' | ZoneCode | CountryCode;
export type RegionFilter = { [regionCode: RegionCode]: boolean };

export function matchesRegion(filter: RegionFilter, cc: CountryCode): boolean {
    return filter.all || filter[cc] || filter[getZoneCode(cc)];
}

export function update(regions: Regions, oldFilter: RegionFilter, code: RegionCode, include: boolean): RegionFilter {
    const filter = { ...oldFilter, [code]: include };

    if (countries[code]) {
        const zc = getZoneCode(code);
        if (include) {
            const zone = find(regions.zones, z => z.code === zc);
            if (zone && zone.countries.every(c => filter[c.code])) {
                filter[zc] = true;
            }
            if (regions.zones.every(z => filter[z.code])) {
                filter.all = true;
            }
        } else {
            filter[zc] = false;
            filter.all = false;
        }
    } else if (regions.zones.some(z => z.code === code)) {
        const zone = find(regions.zones, z => z.code === code);
        if (include) {
            zone?.countries.forEach(c => filter[c.code] = true);
            if (zone && regions.zones.every(z => filter[z.code])) {
                filter.all = true;
            }
        } else {
            filter.all = false;
            zone?.countries.forEach(c => filter[c.code] = false);
        }
    } else {
        filter.all = include;
        regions.zones.forEach(z => {
            filter[z.code] = include;
            z.countries.forEach(c => filter[c.code] = include);
        });
    }


    return filter;
}

export function regionsToString(regions: Regions, filter: RegionFilter): string | null {
    if (filter.all) {
        return null;
    }
    const tokens: RegionCode[] = [];
    regions.zones.forEach(z => {
        if (filter[z.code]) {
            tokens.push(z.code);
        } else {
            z.countries.forEach(c => {
                if (filter[c.code]) {
                    tokens.push(c.code);
                }
            });
        }
    });
    return tokens.join(',');
}

export function regionsFromString(regions: Regions, s: string | null): RegionFilter {
    if (!s) {
        return newRegionFilter(regions, 'all');
    }
    return s.split(',').reduce(
        (filter, token) => update(regions, filter, token as RegionCode, true),
        newRegionFilter(regions),
    );
}

export function newRegionFilter(regions: Regions, onlyRegion: RegionCode | null = null): RegionFilter {
    const filter: RegionFilter = { all: false };
    regions.zones.forEach(z => {
        filter[z.code] = false;
        z.countries.forEach(c => filter[c.code] = false);
    });
    if (onlyRegion) {
        return update(regions, filter, onlyRegion as RegionCode, true);
    }
    return filter;
}
import { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { SetSettings, Settings } from './settings';
import { Country, Data, Regions, Zone } from './types';
import { RegionFilter, newRegionFilter, update } from './region-filter';

export const Controls = ({ data, settings, setSettings }: {
    data: Data,
    settings: Settings,
    setSettings: SetSettings,
}) => {
    const { onlyWSDC, onlyHotel, onlyVideos, onlyResults, hideCancelled, regionFilter } = settings;

    const setRegionFilter = useCallback((regionFilter: RegionFilter) => setSettings(s => ({ ...s, regionFilter })), [setSettings]);

    return <>
        <section className="controls">
            <section className="main-filters">
                <CheckBox id="only-wsdc" checked={onlyWSDC} onToggle={e => setSettings(s => ({ ...s, onlyWSDC: e.target.checked }))}>
                    Only show WSDC events
                </CheckBox>

                <CheckBox id="only-hotel" checked={onlyHotel} onToggle={e => setSettings(s => ({ ...s, onlyHotel: e.target.checked }))}>
                    Only show hotel events
                </CheckBox>

                <CheckBox id="only-videos" checked={onlyVideos} onToggle={e => setSettings(s => ({ ...s, onlyVideos: e.target.checked }))}>
                    Only show events with videos
                </CheckBox>

                <CheckBox id="only-results" checked={onlyResults} onToggle={e => setSettings(s => ({ ...s, onlyResults: e.target.checked }))}>
                    Only show events with results
                </CheckBox>

                <CheckBox id="hide-cancelled" checked={hideCancelled} onToggle={e => setSettings(s => ({ ...s, hideCancelled: e.target.checked }))}>
                    Hide cancelled events
                </CheckBox>
            </section>

            <CountryTree regions={data.regions} regionFilter={settings.regionFilter} setRegionFilter={setRegionFilter} />
            <People data={data} settings={settings} setSettings={setSettings} />
        </section>

        <hr />

        <section>
            Event information is collected automatically and may contain errors.
            Always double-check with event websites.
        </section>

        <hr />

        <section>
            Contact me <a href="https://www.facebook.com/leastfixedpoint">on Facebook</a> or
            {' '}<a href="mailto:tymur.porkuian@gmail.com">by e-mail</a>.
            Check our <a href="privacy-policy.html">privacy policy</a>.
        </section>
    </>;
};

const CheckBox = ({ id, checked, onToggle, children }: {
    id: string,
    checked: boolean,
    onToggle: React.ChangeEventHandler<HTMLInputElement>,
    children: React.ReactNode,
}) => (
    <div className="form-check">
        <input id={id} type="checkbox" className="form-check-input" value={id} onChange={onToggle} checked={checked} />
        <label className="form-check-label" htmlFor={id}>
            {children}
        </label>
    </div>
);

const People = ({ data, settings, setSettings }: { data: Data, settings: Settings, setSettings: SetSettings }) => {
    const [filter, setFilter] = useState('');
    const { people } = data;

    return (
        <section className="people-filter">
            <SelectedPeopleList settings={settings} setSettings={setSettings} />
            <input
                type="text"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Filter by teacher"
                className="form-control"
            />
            <PeopleSuggestions
                settings={settings}
                setSettings={setSettings}
                people={people}
                substring={filter.toLowerCase()}
                onPick={() => setFilter('')}
            />
        </section>
    );
};

const SelectedPeopleList = ({ settings, setSettings }: { settings: Settings, setSettings: SetSettings }) => {
    const { onlyWithPeople } = settings;

    if (onlyWithPeople === null) { return null; }
    return (
        <section className="selected">
            Only showing events with:
            <ul>
                {Array.from(onlyWithPeople).map((person: string) => (
                    <li key={person} onClick={() => setSettings(s => ({ ...s, onlyWithPeople: modifiedSet(onlyWithPeople, [person], false) }))}>
                        {person}
                    </li>
                ))}
            </ul>
        </section>
    );
}

const PeopleSuggestions = ({ settings, setSettings, people, substring, onPick }: {
    people: string[]
    substring: string,
    onPick: (person: string) => void,
    settings: Settings,
    setSettings: SetSettings,
}) => {
    const { onlyWithPeople } = settings;

    if (!substring) {
        return null;
    }

    const filtered = people.filter((p: string) => (p.toLowerCase().indexOf(substring) != -1) && (onlyWithPeople === null || !onlyWithPeople.has(p)));
    if (filtered.length == 0) {
        return <section className="suggestions">
            No people found with this name
        </section>;
    }

    if (filtered.length > 15) {
        return <section className="suggestions">
            Too many results, enter more letters
        </section>;
    }

    return (
        <section className="suggestions">
            <ul>
                {filtered.map((person: string) => (
                    <li key={person} onClick={() => {
                        setSettings(s => ({ ...s, onlyWithPeople: modifiedSet(onlyWithPeople, [person], true) }));
                        onPick(person);
                    }}>{person}</li>
                ))}
            </ul>
        </section>
    );
}

const RegionCheckBox = ({ id, checked, onToggle, onSetOnly, children }: {
    id: string,
    checked: boolean,
    onToggle: React.ChangeEventHandler<HTMLInputElement>,
    onSetOnly: () => void,
    children: React.ReactNode,
}) => (
    <div className="form-check" style={{ display: 'inline-block' }}>
        <input type="checkbox" className="form-check-input" value={id} onChange={onToggle} checked={checked} />
        <label className="form-check-label" onClick={onSetOnly}>
            {children}
        </label>
    </div>
);

const CountryTree = ({ regions, regionFilter, setRegionFilter }: {
    regions: Regions,
    regionFilter: RegionFilter,
    setRegionFilter: (regionFilter: RegionFilter) => void,
}) => {
    return <section className="country-filter">
        <RegionCheckBox
            id="all"
            checked={regionFilter.all}
            onToggle={e => setRegionFilter(update(regions, regionFilter, 'all', e.target.checked))}
            onSetOnly={() => setRegionFilter(newRegionFilter(regions, 'all'))}>
            All countries
        </RegionCheckBox>

        <ul>
            {regions.zones.map((z: Zone) => regionFilter[z.code] !== undefined && (
                <ZoneNode key={z.code} zone={z} regions={regions} regionFilter={regionFilter} setRegionFilter={setRegionFilter} />
            ))}
        </ul>
    </section>;
};

const ZoneNode = ({ zone, regions, regionFilter, setRegionFilter }: {
    zone: Zone,
    regions: Regions,
    regionFilter: RegionFilter,
    setRegionFilter: (regionFilter: RegionFilter) => void,
}) => {
    const [expanded, setExpanded] = useState(zone.countries.some(c => regionFilter[c.code]) && zone.countries.some(c => !regionFilter[c.code]));

    return (
        <li>
            <RegionCheckBox
                id={zone.code}
                checked={regionFilter[zone.code]}
                onToggle={e => setRegionFilter(update(regions, regionFilter, zone.code, e.target.checked))}
                onSetOnly={() => setRegionFilter(newRegionFilter(regions, zone.code))}>
                {zone.name}
            </RegionCheckBox>

            <span className="expcol" onClick={() => setExpanded(!expanded)}>
                {expanded ? '\u25be' : '\u25b8'}
            </span>

            {expanded && (
                <ul>
                    {zone.countries.map(c => (
                        <CountryNode key={c.code} country={c} regions={regions} regionFilter={regionFilter} setRegionFilter={setRegionFilter} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const CountryNode = ({ country, regions, regionFilter, setRegionFilter }: {
    country: Country,
    regions: Regions,
    regionFilter: RegionFilter,
    setRegionFilter: (regionFilter: RegionFilter) => void,
}) => (
    <li>
        <RegionCheckBox
            id={country.code}
            checked={regionFilter[country.code]}
            onToggle={(e) => setRegionFilter(update(regions, regionFilter, country.code, e.target.checked))}
            onSetOnly={() => setRegionFilter(newRegionFilter(regions, country.code))}>
            {country.code != 'unknown' && <>
                <span className={'fi fi-' + country.code} />
                {' '}
            </>}
            {country.name}
        </RegionCheckBox>
    </li>
);

function modifiedSet<T>(set: null | Set<T>, items: T[], action: boolean): null | Set<T> {
    const n = (set === null) ? new Set<T>() : new Set<T>(set);
    for (const item of items) {
        if (action) { n.add(item); } else { n.delete(item); }
    }
    if (n.size === 0) {
        return null;
    }
    return n;
}

export function scrollToCurrentWeek() {
    const currentWeek = document.getElementsByClassName('current-week')?.[0];
    if (!currentWeek) { return; }
    const main = document.getElementsByTagName('main')[0];
    main.scrollTo({ top: main.scrollTop + currentWeek.getBoundingClientRect().y - 100, behavior: 'smooth' })
}

export const ControlsModal = ({ data, settings, setSettings, show, onClose }: {
    data: Data,
    settings: Settings,
    setSettings: SetSettings,
    show: boolean,
    onClose: () => void,
}) => (
    <Modal show={show} onHide={onClose} centered={true}>
        <Modal.Body>
            <Controls data={data} settings={settings} setSettings={setSettings} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>
);
import { isSameDay, eachWeekOfInterval, format, subDays } from "date-fns/fp";
import classNames from "classnames";

import { Event, Weekend } from "./types";
import { formatDate, thisSunday } from "./common";
import { EventCard } from "./event";
import { filterEvents, Settings } from "./settings";

import '../styles/calendar.scss';

type Item = { kind: 'weekend', sunday: Date, events: Event[] } | { kind: 'gap', emptyWeekends: Date[] };

export const Calendar = ({ settings, weekends, firstSunday, lastSunday }: {
    settings: Settings,
    weekends: Map<number, Weekend>,
    firstSunday: Date,
    lastSunday: Date,
}) => {
    const items: Array<Item> = [];

    let emptyWeekends: Date[] = [];
    for (const sunday of eachWeekOfInterval({ start: firstSunday, end: lastSunday })) {
        const weekend = weekends.get(sunday.getTime());
        const events = weekend ? filterEvents(settings, weekend.events) : [];
        if (events.length > 0 || settings.emptyWeekends === 'show') {
            if (emptyWeekends.length > 0) {
                items.push({ kind: 'gap', emptyWeekends });
                emptyWeekends = [];
            }
            items.push({ kind: 'weekend', sunday, events });
        } else if (settings.emptyWeekends === 'collapse') {
            emptyWeekends.push(sunday);
        }
    }
    if (emptyWeekends.length > 0) {
        items.push({ kind: 'gap', emptyWeekends });
    }

    return (
        <ul className="calendar">
            {items.map(item => {
                if (item.kind === 'weekend') {
                    const { sunday, events } = item;
                    const currentWeek = isSameDay(thisSunday, sunday);
                    return <>
                        <li key={formatDate(sunday)} className={classNames('weekend', currentWeek && 'current-week')}>
                            <section className="dates">
                                <SatSun sunday={sunday} />
                            </section>
                            <section className="events">
                                {events.map((e, i) => (
                                    <EventCard key={i} event={e} />
                                ))}
                            </section>
                        </li>
                    </>
                } else {
                    const { emptyWeekends } = item;
                    const currentWeek = emptyWeekends.some(sunday => isSameDay(thisSunday, sunday));
                    return <>
                        <li key={formatDate(emptyWeekends[0])} className={classNames('gap', currentWeek && 'current-week')}>
                            <section className="message">
                                {emptyWeekends.length} {emptyWeekends.length > 1 ? "weekends" : "weekend"} without events
                            </section>
                        </li>
                    </>;
                }
            })}
        </ul>
    );
}

const SatSun = ({ sunday }: { sunday: Date }) => <>
    <$Date date={subDays(1, sunday)} />
    <$Date date={sunday} />
</>;

const $Date = ({ date }: { date: Date }) => <>
    <span className="date">
        <span className="day">{format('d', date)}</span>
        <span className="month">{format('MMMM', date)}</span>
        <span className="year">{format('yyyy', date)}</span>
    </span>
</>;

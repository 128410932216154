/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */

import { match, P } from 'ts-pattern';
import { useMediaQuery } from 'usehooks-ts';
import { memo, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { Event, Venue } from './types';
import { ModalsContext } from './context';
import { countries, CountryCode } from './countries';
import { sortBy } from 'remeda';

const EventCard = memo(function EventCard({ event }: { event: Event }) {
	const { showEvent } = useContext(ModalsContext);
	const cantHover = useMediaQuery('(hover: none)');
	const narrowView = useMediaQuery('(max-width: 39rem)');
	const onTapEvent = (cantHover || narrowView) ? () => showEvent(event) : undefined;

	return (
		<div key={event.name} onClick={onTapEvent}>
			<article className="event" data-cancelled={event.cancelled ? true : null}>
				<Caption name={event.name} countryCode={event.countryCode} />
				<Image url={event.cover} />
				<EventSections event={event} />
			</article>
		</div>
	);
});

export const EventModal = ({ event, onClose }: {
	event: Event | null,
	onClose: () => void,
}) => event == null ? null : (
	<Modal className="event-modal" show={event !== null} onHide={onClose} centered={true}>
		<Modal.Body className="event" data-cancelled={event?.cancelled ? true : null}>
			<Caption countryCode={event.countryCode} name={event.name} />
			<Image url={event.cover} />
			<EventSections event={event} />
		</Modal.Body>
		<Modal.Footer>
			<Button variant="primary" onClick={onClose}>Close</Button>
		</Modal.Footer>
	</Modal>
);

function EventSections({ event }: { event: Event }) {
	const videos = event.links.filter(({ kind }) => kind === 'videos');

	return <>
		{Object.keys(event.facebook).map((fb_id, i) => (
			<FacebookLink key={`facebook-${i}`} fbId={fb_id} info={event.facebook[fb_id]} />
		))}

		{event.links.filter(({ kind }) => kind === 'results').map(({ kind, url }, i) => (
			<SingleLink key={`link-${kind}-${i}`} code={kind} title="Results" url={url} />
		))}

		{videos.length > 1 ? (
			<section key="videos" className="link">
				<b>Videos:</b>
				<ol>
					{videos.map(({ kind, url, title }, i) => (
						<li key={i}><a href={url} title={url}>{title || url}</a></li>
					))}
				</ol>
			</section>
		) : videos.map(({ kind, url }, i) => (
			<SingleLink key="videos" code={kind} title="Videos" url={url} />
		))}

		{(event.people && !!event.people.length) && (
			<People people={event.people} />
		)}

		{sortBy(Object.keys(event.venues), venueOrder).map((key: string) => (
			<Location key={key} venue={event.venues[key]} />
		))}

		{event.links.filter(({ kind }) => !isSpecialLinkKey(kind)).map(({ kind, url }, i) => (
			<SingleLink key={`link-${kind}-${i}`} code={kind} title="Website" url={url} />
		))}

		{!!eventTags(event).length && (
			<Tags tags={eventTags(event)} />
		)}
	</>;
}

const People = ({ people }: { people: string[] }) => (
	<section className="people">
		<ul>
			{people.map(name => (
				<li key={name}>{name}</li>
			))}
		</ul>
	</section>
);

const Caption = ({ countryCode, name }: { countryCode: CountryCode, name: string }) => (
	<header className="caption">
		<Flag countryCode={countryCode} />
		<span className="name">{name}</span>
	</header>
);

const Image = ({ url }: { url: string | null }) => (
	<section className="image">
		{url && (
			<img src={url} onError={({ target }) => {
				(target as any).onerror = null;
				(target as any).src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw== ';
			}} />
		)}
	</section>
);

const Tags = ({ tags }: { tags: string[] }) => (
	<section className="tags">
		<ul>
			{tags.map((tag) => (
				<li key={tag} className={tag}>{tag}</li>
			))}
		</ul>
	</section>
);

export function venueOrder(key: string): string {
	const index = ["fb2", "wsdc", "facebook2", "fb2-raw", "facebook"].indexOf(key);
	return index >= 0 ? `__${index}` : key
}

export const Flag = ({ countryCode }: { countryCode: CountryCode }) => (countryCode == 'unknown') ? null : (
	<span className={`fi fi-${countryCode}`} title={countries[countryCode].name} />
);

export function isSpecialLinkKey(key: string): boolean {
	return key.startsWith('facebook:event') || key === 'results' || key === 'videos';
}

export function eventTags(event: Event): string[] {
	const { links, hotel, wsdc, cancelled } = event;
	const tags = [];
	if (links && links.some(({ kind }) => kind === 'videos')) {
		tags.push('videos');
	}
	if (links && links.some(({ kind }) => kind === 'results')) {
		tags.push('results');
	}
	if (hotel) {
		tags.push('hotel');
	}
	if (wsdc) {
		tags.push('wsdc');
	}
	if (cancelled) {
		tags.push('cancelled');
	}
	return tags;
}

export const FacebookLink = ({ fbId, info }: { fbId: string, info: { going?: number, responded?: number } }) => (
	<section className="link">
		<a href={`http://fb.com/${fbId}`}>Facebook</a>
		{match(info)
			.with({
				going: P.number,
				responded: P.optional(P.nullish),
			}, ({ going }) => <>: {going} going</>)
			.with({
				going: P.optional(P.nullish),
				responded: P.number,
			}, ({ responded }) => <>: {responded} responded</>)
			.with({
				going: P.number,
				responded: P.number,
			}, ({ going, responded }) => <>: {responded} responded, {going} going</>)
			.otherwise(() => null)}
	</section>
);

export const SingleLink = ({ code, title, url }: { code: string, title: string, url: string }) => (
	<section className="link">
		<b>{title}:</b> <a href={url} title={url}>{url}</a>
	</section>
);

export const Location = ({ venue: { name, address, url } }: { venue: Venue }) => {
	const tokens: Array<null | string | React.ReactElement<any>> = [name, address].filter(t => !!t);
	if (!tokens.length) { return null; }
	if (url) { tokens[0] = <a href={url}>{tokens[0]}</a>; }

	return (
		<section className="location">
			<b>Location: </b>
			{tokens[0]}
			{(tokens.length > 1) && (
				<>, {tokens[1]}</>
			)}
		</section>
	);
};

export { EventCard };

import { getYear, parseISO } from 'date-fns';
import { map, mapValues, maxBy, pipe } from 'remeda';

import { buildRegions } from './countries';
import { Input, InputEvent, Data, Event, Weekend } from './types';
import { addDays, isAfter, nextSunday, subDays } from 'date-fns/fp';
import { thisYear } from './common';

export function inputToData(input: Input): Data {
    const weekends = new Map<number, Weekend>();
    let firstYear = thisYear;
    let lastYear = thisYear;

    for (const event of input.events) {
        const sunday = parseISO(event.sunday);
        const ws = weekends.get(sunday.getTime()) || { sunday, events: [] };
        ws.events.push(inputEventToDataEvent(event));
        weekends.set(sunday.getTime(), ws);
        if (getYear(sunday) > lastYear) lastYear = getYear(sunday);
        if (getYear(sunday) < firstYear) firstYear = getYear(sunday);
    }

    for (const ws of weekends.values()) {
        ws.events = sortEvents(ws.events);
    }

    return {
        weekends,
        regions: buildRegions(input.events),
        people: input.people,
        firstYear,
        lastYear,
    };
}

export function sundaysForYear(year: number): Date[] {
    const sundays = [];
    const first = pipe(new Date(year, 0, 1), subDays(13), nextSunday);
    const last = pipe(new Date(year, 11, 31), addDays(6), nextSunday);
    for (let sunday = first; !isAfter(last, sunday); sunday = nextSunday(sunday)) {
        sundays.push(sunday);
    }
    return sundays;
}

function sortEvents(events: Event[]): Event[] {
    return events.sort((e1, e2) => {
        const going1 = maxBy([0,
            ...pipe(e1.facebook, Object.values, map(i => i.going)),
            ...pipe(e1.facebook, Object.values, map(i => i.responded)),
        ], x => x);
        const going2 = maxBy([0,
            ...pipe(e2.facebook, Object.values, map(i => i.going)),
            ...pipe(e2.facebook, Object.values, map(i => i.responded)),
        ], x => x);
        if (going1 > going2) { return -1; }
        if (going1 < going2) { return 1; }
        return e1.name > e2.name ? 1 : -1;
    });
}

function inputEventToDataEvent(event: InputEvent): Event {
    let cover = event.cover || null;
    if (cover) {
        cover = cover.replace('https://weekenders.s3.eu-central-1.amazonaws.com/', '');
        cover = `https://d3q8bcwlla5p80.cloudfront.net/288x120/${cover}`
    }
    return {
        name: event.name!,
        cover: cover,
        facebook: event.facebook || {},
        links: event.links || [],
        people: event.people || [],
        venues: mapValues(
            event.venues || {},
            ({ name = null, address = null, url = null }, code) => ({ code, name, address, url })),
        sunday: parseISO(event.sunday),
        countryCode: event.country_code || 'unknown',
        hotel: event.hotel || false,
        wsdc: event.wsdc || false,
        cancelled: event.cancelled || false,
        hasVideos: (event.links || []).some(({ kind }) => kind === 'videos'),
        hasResults: (event.links || []).some(({ kind }) => kind === 'results'),
    };
}
import { formatISO } from "date-fns";
import { setISODay, startOfDay } from "date-fns/fp";
import { pipe } from 'remeda';

export const thisSunday = pipe(new Date(), startOfDay, setISODay(7));
export const thisYear = new Date().getFullYear();

export function formatDate(date: Date) {
    return formatISO(date, { representation: 'date' });
}

'use client';

import useSWR from 'swr';
import { useEffect, useState, useContext, useCallback } from 'react'
import { addDays, getYear, nextSunday, subDays } from 'date-fns/fp';
import { pipe, range } from 'remeda';
import { useQueryState } from 'nuqs';

import { Data, Event } from '../components/types';
import { EventModal } from '../components/event';
import { Controls, ControlsModal, scrollToCurrentWeek } from '../components/sidebar';
import { SetSettings, Settings } from '../components/settings';
import { ModalsContext } from '../components/context';
import { Calendar } from '../components/calendar';
import { thisYear } from '../components/common';
import { regionsFromString, regionsToString } from '../components/region-filter';
import { inputToData } from '../components/data';

import '../styles/globals.scss';
import '../styles/index.scss';

export default function Page() {
  const { data } = useSWR('events.json', async (url) => {
    const response = await fetch(url);
    const json = await response.json();
    return inputToData(json);
  });

  return data ? <Main data={data} /> : 'Loading...';
}

function Main({ data }: { data: Data }) {
  const [regionFilterParam, setRegionFilterParam] = useQueryState('regions');

  const [settings, setSettings] = useState({
    year: getYear(new Date()),
    onlyWithPeople: null,
    onlyWSDC: false,
    onlyHotel: false,
    onlyVideos: false,
    onlyResults: false,
    hideCancelled: false,
    regionFilter: regionsFromString(data.regions, regionFilterParam),
    emptyWeekends: 'collapse',
  } as Settings);

  useEffect(() => {
    setRegionFilterParam(regionsToString(data.regions, settings.regionFilter));
  }, [setRegionFilterParam, settings.regionFilter, data.regions]);

  useEffect(() => { scrollToCurrentWeek(); }, [/* once */]);

  const [selectedEvent, setSelectedEvent] = useState<null | Event>(null);
  const [showControls, setShowControls] = useState(false);
  const setYear = useCallback((year: number) => setSettings(settings => ({ ...settings, year })), []);

  return <>
    <ModalsContext.Provider value={{ showEvent: setSelectedEvent, showControls: () => setShowControls(true) }}>

      <TopBar settings={settings} data={data} setSettings={setSettings} />

      <Events settings={settings} data={data} setYear={setYear} />

      <aside>
        <Controls data={data} settings={settings} setSettings={setSettings} />
      </aside>

      <ControlsModal data={data} settings={settings} setSettings={setSettings} show={showControls} onClose={() => setShowControls(false)} />
      <EventModal event={selectedEvent} onClose={() => setSelectedEvent(null)} />

    </ModalsContext.Provider>
  </>;
};

const Events = ({
  data: { weekends, firstYear, lastYear },
  settings,
  setYear,
}: {
  data: Data,
  settings: Settings,
  setYear: (year: number) => void,
}) => {
  return (
    <main>
      {settings.year && (
        <GoToPrevYear selectedYear={settings.year} firstYear={firstYear} setYear={setYear} />
      )}

      <Calendar
        firstSunday={pipe(new Date(settings.year ?? firstYear, 0, 1), subDays(13), nextSunday)}
        lastSunday={pipe(new Date(settings.year ?? lastYear, 11, 31), addDays(6), nextSunday)}
        weekends={weekends}
        settings={settings} />

      {settings.year && (
        <GoToNextYear selectedYear={settings.year} lastYear={lastYear} setYear={setYear} />
      )}
    </main>
  );
};

const GoToPrevYear = ({ firstYear, selectedYear, setYear }: {
  firstYear: number,
  selectedYear: number,
  setYear: (year: number) => void,
}) => (
  <div className="loadmore past">
    {(selectedYear > firstYear) ? (
      <button type="button" onClick={() => setYear(selectedYear - 1)}>
        Go to {selectedYear - 1}
      </button>
    ) : (
      <span>No events in the database before {firstYear}</span>
    )}
  </div>
);

const GoToNextYear = ({ lastYear, selectedYear, setYear }: {
  lastYear: number,
  selectedYear: number,
  setYear: (year: number) => void,
}) => (
  <div className="loadmore future">
    {(selectedYear < lastYear) ? (
      <button type="button" onClick={() => setYear(selectedYear + 1)}>
        Go to {selectedYear + 1}
      </button>
    ) : (
      <span>No events in the database after {lastYear}</span>
    )}
  </div>
);

const TopBar = ({ data, settings, setSettings }: { data: Data, settings: Settings, setSettings: SetSettings }) => {
  const { showControls } = useContext(ModalsContext);

  const [showYears, setShowYears] = useState(false);

  return (
    <nav>
      <a className="logo" href="?countries=all">W</a>

      <button className="show-years" onClick={() => setShowYears(!showYears)}>
        {settings.year ?? 'ALL'}
      </button>

      {showYears && <YearPicker data={data} settings={settings} setSettings={setSettings} setShowYears={setShowYears} />}

      <button className="goto-today" onClick={() => {
        setSettings(settings => ({ ...settings, year: thisYear }));
        setTimeout(scrollToCurrentWeek, 0);
      }}>
        CURRENT<br />WEEK
      </button>

      <button className="toggle-sidebar" type="button" onClick={showControls}>
        <img
          src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"
          alt="Show or hide sidebar"
          width="24" height="24"
        />
      </button>
    </nav>
  );
};

const YearPicker = ({ data, settings, setSettings, setShowYears }: {
  data: Data,
  settings: Settings,
  setSettings: SetSettings,
  setShowYears: (show: boolean) => void,
}) => {
  return (
    <section className="year-picker">
      <ul>
        {range(data.firstYear, data.lastYear + 1).map(year => (
          <li key={year} onClick={() => {
            setSettings(s => ({ ...s, year }));
            setShowYears(false);
          }}>{year}</li>
        ))}
      </ul>

      <div className="collapsed-switch">
        <div className="form-check form-switch">
          <input
            type="checkbox" className="form-check-input" id="collapsed"
            checked={settings.year === null}
            onChange={e => {
              setSettings(s => ({ ...s, year: e.target.checked ? null : thisYear }));
              setShowYears(false);
            }} />
          <label className="form-check-label" htmlFor="collapsed">Show all years at once</label>
        </div>
      </div>
    </section>
  );
}
import { Event } from './types';
import { RegionFilter, matchesRegion } from './region-filter';

export type EmptyWeekendsMode = 'hide' | 'show' | 'collapse';

export type Settings = {
    year: number | null;
    onlyWithPeople: null | Set<string>;
    onlyWSDC: boolean;
    onlyHotel: boolean;
    onlyVideos: boolean;
    onlyResults: boolean;
    hideCancelled: boolean;
    regionFilter: RegionFilter;
    emptyWeekends: EmptyWeekendsMode;
}

export type SetSettings = (update: ((settings: Settings) => Settings)) => void;

export function showEvent(s: Settings, e: Event): boolean {
    if (!matchesRegion(s.regionFilter, e.countryCode)) {
        return false;
    }
    if (s.onlyWSDC && !e.wsdc) {
        return false;
    }
    if (s.onlyHotel && !e.hotel) {
        return false;
    }
    if (s.onlyVideos && !e.hasVideos) {
        return false;
    }
    if (s.onlyResults && !e.hasResults) {
        return false;
    }
    if (s.hideCancelled && e.cancelled) {
        return false;
    }
    if (s.onlyWithPeople !== null && !e.people.some((p) => s.onlyWithPeople!.has(p))) {
        return false;
    }
    return true;
}

export function filterEvents(s: Settings, events: Event[]): Event[] {
    return events.filter((e) => showEvent(s, e));
}
